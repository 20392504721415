import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
// import { ReactComponent as GoogleMapIcon } from "images/google-maps-icon.svg";
import GoogleMapIcon from "images/google-maps-icon.svg"
const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-blue-500`;
const Phone = tw.span`text-sm mt-0 block text-black`;
const Map = tw.span`text-sm underline text-blue-600 hover:text-blue-800 visited:text-purple-600`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      {/* <ContactUsForm /> */}
      <ContactDetails
        cards={[
          {
            title: "Tonk Road",
            description: (
              <>
                <Address>
                  <AddressLine>Shop No. 51, Scheme No. 6</AddressLine>
                  <AddressLine>Tonk Rd, Jai Ambey Nagar, Jaipur, 302011</AddressLine>
                </Address>
                <Map> 
                <img src={GoogleMapIcon} alt="Map Logo" style={{ display: "inherit" }} /> <a href="https://goo.gl/maps/3ZtxFVV6XrXssPxm6" target="_blank">Directions</a> 
                </Map>
                <Email><a href="mailto:sales@moretech.co.in">sales@moretech.co.in</a></Email>
                <Phone>0141-2552991</Phone>
              </>
            )
          },
          {
            title: "Pratap Plaza",
            description: (
              <>
                <Address>
                  <AddressLine>#B-1, Pratap Plaza</AddressLine>
                  <AddressLine>Pratap Nagar, Tonk Road, Jaipur, 302011</AddressLine>
                </Address>
                <Map> 
                <img src={GoogleMapIcon} alt="Map Logo" style={{ display: "inherit" }} /> <a href="https://goo.gl/maps/1qyFDYHeEyNy5Qsq5" target="_blank">Directions</a> 
                </Map>
                <Email><a href="mailto:sales@moretech.co.in">sales@moretech.co.in</a></Email>
                <Phone>0141-2794124</Phone>
              </>
            )
          },
          {
            title: "Model Town",
            description: (
              <>
                <Address>
                  <AddressLine>Amar Plaza, Opp D-Mart</AddressLine>
                  <AddressLine>Model Town, Jagatpura, Jaipur, 302017</AddressLine>
                </Address>
                <Map> 
                <img src={GoogleMapIcon} alt="Map Logo" style={{ display: "inherit" }} /> <a href="https://goo.gl/maps/TLoUQU3d8EDhtCiV9" target="_blank">Directions</a> 
                </Map>
                <Email> <a href="mailto:sales@moretech.co.in">sales@moretech.co.in</a></Email>
                <Phone>0141-4064946</Phone>
              </>
            )
          }
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
