import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-primary-500! text-gray-100!`}
`;

const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;

const CardReview = tw.div`font-medium text-xs text-gray-600`;

const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-primary-500`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;
const CardPrice = tw.p`mt-4 text-xl font-bold`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

// {
//   imageSrc:
//     "https://images.unsplash.com/photo-1432139555190-58524dae6a55?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=80",
//   title: "Macaroni",
//   content: "Cheese Pizza",
//   price: "$2.99",
//   rating: "4.8",
//   reviews: "32",
//   url: "#"
// },

export default ({
  heading = "Checkout the Products",
  tabs = {
    Laptops: [
      {
        imageSrc:
          "https://i.dell.com/is/image/DellContent/content/dam/ss2/product-images/dell-client-products/notebooks/inspiron-notebooks/14-5420/media-gallery/notebook-inspiron-14-5420-gy-fpr-gallery-5.psd?qlt=90,0&op_usm=1.75,0.3,2,0&resMode=sharp&pscan=auto&fmt=png-alpha&hei=500",
        title: "New Inspiron 14 Laptop",
        content: "12th Generation Intel® Core™ i5-1235U, 	Windows 11 Home, 	16 GB, 2 x 8 GB, DDR4, 3200 MHz, 512 GB, M.2, PCIe NVMe, SSD",
        url: "https://www.dell.com/en-in/work/shop/business-laptop-notebook-computers/new-inspiron-14-laptop/spd/inspiron-14-5425-laptop/icc-c782525win8"
      },
      
      {
        imageSrc:
          "https://i.dell.com/is/image/DellContent/content/dam/ss2/product-images/dell-client-products/notebooks/inspiron-notebooks/16-5620/media-gallery/notebook-inspiron-16-5620-2-in-1-gy-fpr-gallery-4.psd?qlt=90,0&op_usm=1.75,0.3,2,0&resMode=sharp&pscan=auto&fmt=png-alpha&hei=500",
        title: "New Inspiron 16 laptop",
        content: "12th Generation Intel® Core™ i5-1240P, Windows 11 Home, 16 GB, 2 x 8 GB, DDR4, 3200 MHz, 512 GB, M.2, PCIe NVMe, SSD",
        url: "https://www.dell.com/en-in/work/shop/business-laptop-notebook-computers/new-inspiron-14-laptop/spd/inspiron-14-5425-laptop/icc-c782525win8"
      },
      {
        imageSrc:
          "https://i.dell.com/is/image/DellContent/content/dam/ss2/product-images/dell-client-products/notebooks/vostro-notebooks/vostro-14-3420/media-gallery/black/notebook-vostro-14-3420-gallery-2.psd?qlt=90,0&op_usm=1.75,0.3,2,0&resMode=sharp&pscan=auto&fmt=png-alpha&hei=500",
        title: "Vostro 14 3420 Laptop",
        content: "12th Gen Intel® Core™ i5-1235U, Windows 11 Home, 16 GB, 1 x 16 GB, DDR4, 2666 MHz, 512 GB, M.2, PCIe NVMe, SSD",
        url: "https://www.dell.com/en-in/work/shop/business-laptop-notebook-computers/vostro-14-3420-laptop/spd/vostro-14-3420-laptop/sbo-d554007win9be"
      },
      {
        imageSrc:
          "https://i.dell.com/is/image/DellContent/content/dam/ss2/product-images/dell-client-products/notebooks/vostro-notebooks/vostro-16-5620/assets/media-gallery/gy/vostro-16-5620-nt-gallery-7.psd?qlt=90,0&op_usm=1.75,0.3,2,0&resMode=sharp&pscan=auto&fmt=png-alpha&hei=500",
        title: "Vostro 5620 Laptop",
        content: "12th Gen Intel® Core™ i5-1240P, 	Windows 11 Home, 8 GB, 1 x 8 GB, DDR4, 3200 MHz, 512 GB, M.2, PCIe NVMe, SSD",
        url: "https://www.dell.com/en-in/work/shop/business-laptop-notebook-computers/vostro-5620-laptop/spd/vostro-16-5620-laptop/sbo-d562004win9"
      },
      {
        imageSrc:
          "https://i.dell.com/is/image/DellContent//content/dam/ss2/product-images/dell-client-products/notebooks/latitude-notebooks/14-3420/media-gallery/peripherals_laptop_latitude_3420nt_gallery_1.psd?qlt=90,0&op_usm=1.75,0.3,2,0&resMode=sharp&pscan=auto&fmt=png-alpha&hei=500",
        title: "Latitude 3420 Laptop",
        content: "11th Gen Intel® Core™ i5-1135G7, Windows 11 Pro, 8 GB, 1 x 8 GB, DDR4, 3200 MHz, 256 GB, M.2, PCIe NVMe, SSD",
        url: "https://www.dell.com/en-in/work/shop/business-laptop-notebook-computers/new-latitude-3420-laptop/spd/latitude-14-3420-laptop/n001l3420135in9"
      },
      {
        imageSrc:"https://i.dell.com/sites/csimages/Product_Imagery/all/la3330t.png",
        title: "Latitude 3330 Laptop",
        content: "11th Gen Intel® Core™ i3-1125G4, Windows 10 Pro, 	8 GB, LPDDR4x, 4266 MHz, 	256 GB, M.2, PCIe NVMe, SSD",
        url: "https://www.dell.com/en-in/work/shop/business-laptop-notebook-computers/latitude-3330-laptop/spd/latitude-13-3330-laptop/al3330_vp"
      },
      {
        imageSrc:
          "https://i.dell.com/is/image/DellContent/content/dam/ss2/product-images/dell-client-products/notebooks/xps-notebooks/xps-13-9315/media-gallery/notebook-xps-9315-nt-blue-hero-right.psd?qlt=90,0&op_usm=1.75,0.3,2,0&resMode=sharp&pscan=auto&fmt=png-alpha&hei=500",
        title: "New XPS 13 Laptop",
        content: "12th Generation Intel® Core™ i5-1230U, Windows 11 Pro, 	16 GB, LPDDR5, 5200 MHz, 512GB PCIe NVMe x2 SSD",
        url: "https://www.dell.com/en-in/work/shop/business-laptop-notebook-computers/new-xps-13-laptop/spd/xps-13-9315-laptop/icc-c786507win8sbo"
      },
    ],
    PCs: getRandomCards(),
    Accessories: getRandomCards(),
    Workstations: getRandomCards()
  }
}) => {
  /*
   * To customize the tabs, pass in data using the `tabs` prop. It should be an object which contains the name of the tab
   * as the key and value of the key will be its content (as an array of objects).
   * To see what attributes are configurable of each object inside this array see the example above for "Starters".
   */
  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderRow>
          <Header>{heading}</Header>
          <TabsControl>
            {Object.keys(tabs).map((tabName, index) => (
              <TabControl key={index} active={activeTab === tabName} onClick={() => setActiveTab(tabName)}>
                {tabName}
              </TabControl>
            ))}
          </TabsControl>
        </HeaderRow>

        {tabsKeys.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale:1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale:0.8,
                display: "none",
              }
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? "current" : "hidden"}
            animate={activeTab === tabKey ? "current" : "hidden"}
          >
            {tabs[tabKey].map((card, index) => (
              <CardContainer key={index}>
                <Card className="group" href={card.url} initial="rest" whileHover="hover" animate="rest">
                  <CardImageContainer imageSrc={card.imageSrc}>
                    <CardRatingContainer>
                      <CardRating>
                        <StarIcon />
                        {card.rating}
                      </CardRating>
                      <CardReview>({card.reviews})</CardReview>
                    </CardRatingContainer>
                    <CardHoverOverlay
                      variants={{
                        hover: {
                          opacity: 1,
                          height: "auto"
                        },
                        rest: {
                          opacity: 0,
                          height: 0
                        }
                      }}
                      transition={{ duration: 0.3 }}
                    >
                      <CardButton>Buy Now</CardButton>
                    </CardHoverOverlay>
                  </CardImageContainer>
                  <CardText>
                    <CardTitle>{card.title}</CardTitle>
                    <CardContent>{card.content}</CardContent>
                    <CardPrice>{card.price}</CardPrice>
                  </CardText>
                </Card>
              </CardContainer>
            ))}
          </TabContent>
        ))}
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

/* This function is only there for demo purposes. It populates placeholder cards */
const getRandomCards = () => {
  const cards = [
    {
      imageSrc:
        "https://i.dell.com/is/image/DellContent/content/dam/ss2/product-images/dell-client-products/notebooks/inspiron-notebooks/14-5420/media-gallery/notebook-inspiron-14-5420-gy-fpr-gallery-5.psd?qlt=90,0&op_usm=1.75,0.3,2,0&resMode=sharp&pscan=auto&fmt=png-alpha&hei=500",
      title: "New Inspiron 14 Laptop",
      content: "12th Generation Intel® Core™ i5-1235U, 	Windows 11 Home, 	16 GB, 2 x 8 GB, DDR4, 3200 MHz, 512 GB, M.2, PCIe NVMe, SSD",
      url: "https://www.dell.com/en-in/work/shop/business-laptop-notebook-computers/new-inspiron-14-laptop/spd/inspiron-14-5425-laptop/icc-c782525win8"
    },
    
    {
      imageSrc:
        "https://i.dell.com/is/image/DellContent/content/dam/ss2/product-images/dell-client-products/notebooks/inspiron-notebooks/16-5620/media-gallery/notebook-inspiron-16-5620-2-in-1-gy-fpr-gallery-4.psd?qlt=90,0&op_usm=1.75,0.3,2,0&resMode=sharp&pscan=auto&fmt=png-alpha&hei=500",
      title: "New Inspiron 16 laptop",
      content: "12th Generation Intel® Core™ i5-1240P, Windows 11 Home, 16 GB, 2 x 8 GB, DDR4, 3200 MHz, 512 GB, M.2, PCIe NVMe, SSD",
      url: "https://www.dell.com/en-in/work/shop/business-laptop-notebook-computers/new-inspiron-14-laptop/spd/inspiron-14-5425-laptop/icc-c782525win8"
    },
    {
      imageSrc:
        "https://i.dell.com/is/image/DellContent/content/dam/ss2/product-images/dell-client-products/notebooks/vostro-notebooks/vostro-14-3420/media-gallery/black/notebook-vostro-14-3420-gallery-2.psd?qlt=90,0&op_usm=1.75,0.3,2,0&resMode=sharp&pscan=auto&fmt=png-alpha&hei=500",
      title: "Vostro 14 3420 Laptop",
      content: "12th Gen Intel® Core™ i5-1235U, Windows 11 Home, 16 GB, 1 x 16 GB, DDR4, 2666 MHz, 512 GB, M.2, PCIe NVMe, SSD",
      url: "https://www.dell.com/en-in/work/shop/business-laptop-notebook-computers/vostro-14-3420-laptop/spd/vostro-14-3420-laptop/sbo-d554007win9be"
    },
    {
      imageSrc:
        "https://i.dell.com/is/image/DellContent/content/dam/ss2/product-images/dell-client-products/notebooks/vostro-notebooks/vostro-16-5620/assets/media-gallery/gy/vostro-16-5620-nt-gallery-7.psd?qlt=90,0&op_usm=1.75,0.3,2,0&resMode=sharp&pscan=auto&fmt=png-alpha&hei=500",
      title: "Vostro 5620 Laptop",
      content: "12th Gen Intel® Core™ i5-1240P, 	Windows 11 Home, 8 GB, 1 x 8 GB, DDR4, 3200 MHz, 512 GB, M.2, PCIe NVMe, SSD",
      url: "https://www.dell.com/en-in/work/shop/business-laptop-notebook-computers/vostro-5620-laptop/spd/vostro-16-5620-laptop/sbo-d562004win9"
    },
    {
      imageSrc:
        "https://i.dell.com/is/image/DellContent//content/dam/ss2/product-images/dell-client-products/notebooks/latitude-notebooks/14-3420/media-gallery/peripherals_laptop_latitude_3420nt_gallery_1.psd?qlt=90,0&op_usm=1.75,0.3,2,0&resMode=sharp&pscan=auto&fmt=png-alpha&hei=500",
      title: "Latitude 3420 Laptop",
      content: "11th Gen Intel® Core™ i5-1135G7, Windows 11 Pro, 8 GB, 1 x 8 GB, DDR4, 3200 MHz, 256 GB, M.2, PCIe NVMe, SSD",
      url: "https://www.dell.com/en-in/work/shop/business-laptop-notebook-computers/new-latitude-3420-laptop/spd/latitude-14-3420-laptop/n001l3420135in9"
    },
    {
      imageSrc:"https://i.dell.com/sites/csimages/Product_Imagery/all/la3330t.png",
      title: "Latitude 3330 Laptop",
      content: "11th Gen Intel® Core™ i3-1125G4, Windows 10 Pro, 	8 GB, 	256 GB, M.2, PCIe NVMe, SSD,",
      url: "https://www.dell.com/en-in/work/shop/business-laptop-notebook-computers/latitude-3330-laptop/spd/latitude-13-3330-laptop/al3330_vp"
    },
    {
      imageSrc:
        "https://i.dell.com/is/image/DellContent/content/dam/ss2/product-images/dell-client-products/notebooks/xps-notebooks/xps-13-9315/media-gallery/notebook-xps-9315-nt-blue-hero-right.psd?qlt=90,0&op_usm=1.75,0.3,2,0&resMode=sharp&pscan=auto&fmt=png-alpha&hei=500",
      title: "New XPS 13 Laptop",
      content: "12th Generation Intel® Core™ i5-1230U, Windows 11 Pro, 	16 GB, LPDDR5, 5200 MHz, 512GB PCIe NVMe x2 SSD",
      url: "https://www.dell.com/en-in/work/shop/business-laptop-notebook-computers/new-xps-13-laptop/spd/xps-13-9315-laptop/icc-c786507win8sbo"
    }
  ];

  // Shuffle array
  return cards.sort(() => Math.random() - 0.5);
};

const getDesktopCards = () => {
  const cards = [
    {
      imageSrc:
        "https://i.dell.com/is/image/DellContent/content/dam/ss2/product-images/dell-client-products/notebooks/inspiron-notebooks/14-5420/media-gallery/notebook-inspiron-14-5420-gy-fpr-gallery-5.psd?qlt=90,0&op_usm=1.75,0.3,2,0&resMode=sharp&pscan=auto&fmt=png-alpha&hei=500",
      title: "New Inspiron 14 Laptop",
      content: "12th Generation Intel® Core™ i5-1235U, 	Windows 11 Home, 	16 GB, 2 x 8 GB, DDR4, 3200 MHz, 512 GB, M.2, PCIe NVMe, SSD",
      url: "https://www.dell.com/en-in/work/shop/business-laptop-notebook-computers/new-inspiron-14-laptop/spd/inspiron-14-5425-laptop/icc-c782525win8"
    },
    
    {
      imageSrc:
        "https://i.dell.com/is/image/DellContent/content/dam/ss2/product-images/dell-client-products/notebooks/inspiron-notebooks/16-5620/media-gallery/notebook-inspiron-16-5620-2-in-1-gy-fpr-gallery-4.psd?qlt=90,0&op_usm=1.75,0.3,2,0&resMode=sharp&pscan=auto&fmt=png-alpha&hei=500",
      title: "New Inspiron 16 laptop",
      content: "12th Generation Intel® Core™ i5-1240P, Windows 11 Home, 16 GB, 2 x 8 GB, DDR4, 3200 MHz, 512 GB, M.2, PCIe NVMe, SSD",
      url: "https://www.dell.com/en-in/work/shop/business-laptop-notebook-computers/new-inspiron-14-laptop/spd/inspiron-14-5425-laptop/icc-c782525win8"
    },
    {
      imageSrc:
        "https://i.dell.com/is/image/DellContent/content/dam/ss2/product-images/dell-client-products/notebooks/vostro-notebooks/vostro-14-3420/media-gallery/black/notebook-vostro-14-3420-gallery-2.psd?qlt=90,0&op_usm=1.75,0.3,2,0&resMode=sharp&pscan=auto&fmt=png-alpha&hei=500",
      title: "Vostro 14 3420 Laptop",
      content: "12th Gen Intel® Core™ i5-1235U, Windows 11 Home, 16 GB, 1 x 16 GB, DDR4, 2666 MHz, 512 GB, M.2, PCIe NVMe, SSD",
      url: "https://www.dell.com/en-in/work/shop/business-laptop-notebook-computers/vostro-14-3420-laptop/spd/vostro-14-3420-laptop/sbo-d554007win9be"
    },
    {
      imageSrc:
        "https://i.dell.com/is/image/DellContent/content/dam/ss2/product-images/dell-client-products/notebooks/vostro-notebooks/vostro-16-5620/assets/media-gallery/gy/vostro-16-5620-nt-gallery-7.psd?qlt=90,0&op_usm=1.75,0.3,2,0&resMode=sharp&pscan=auto&fmt=png-alpha&hei=500",
      title: "Vostro 5620 Laptop",
      content: "12th Gen Intel® Core™ i5-1240P, 	Windows 11 Home, 8 GB, 1 x 8 GB, DDR4, 3200 MHz, 512 GB, M.2, PCIe NVMe, SSD",
      url: "https://www.dell.com/en-in/work/shop/business-laptop-notebook-computers/vostro-5620-laptop/spd/vostro-16-5620-laptop/sbo-d562004win9"
    },
    {
      imageSrc:
        "https://i.dell.com/is/image/DellContent//content/dam/ss2/product-images/dell-client-products/notebooks/latitude-notebooks/14-3420/media-gallery/peripherals_laptop_latitude_3420nt_gallery_1.psd?qlt=90,0&op_usm=1.75,0.3,2,0&resMode=sharp&pscan=auto&fmt=png-alpha&hei=500",
      title: "Latitude 3420 Laptop",
      content: "11th Gen Intel® Core™ i5-1135G7, Windows 11 Pro, 8 GB, 1 x 8 GB, DDR4, 3200 MHz, 256 GB, M.2, PCIe NVMe, SSD",
      url: "https://www.dell.com/en-in/work/shop/business-laptop-notebook-computers/new-latitude-3420-laptop/spd/latitude-14-3420-laptop/n001l3420135in9"
    },
    {
      imageSrc:"https://i.dell.com/sites/csimages/Product_Imagery/all/la3330t.png",
      title: "Latitude 3330 Laptop",
      content: "11th Gen Intel® Core™ i3-1125G4, Windows 10 Pro, 	8 GB, 	256 GB, M.2, PCIe NVMe, SSD,",
      url: "https://www.dell.com/en-in/work/shop/business-laptop-notebook-computers/latitude-3330-laptop/spd/latitude-13-3330-laptop/al3330_vp"
    },
    {
      imageSrc:
        "https://i.dell.com/is/image/DellContent/content/dam/ss2/product-images/dell-client-products/notebooks/xps-notebooks/xps-13-9315/media-gallery/notebook-xps-9315-nt-blue-hero-right.psd?qlt=90,0&op_usm=1.75,0.3,2,0&resMode=sharp&pscan=auto&fmt=png-alpha&hei=500",
      title: "New XPS 13 Laptop",
      content: "12th Generation Intel® Core™ i5-1230U, Windows 11 Pro, 	16 GB, LPDDR5, 5200 MHz, 512GB PCIe NVMe x2 SSD",
      url: "https://www.dell.com/en-in/work/shop/business-laptop-notebook-computers/new-xps-13-laptop/spd/xps-13-9315-laptop/icc-c786507win8sbo"
    }
  ];

  // Shuffle array
  return cards
};


