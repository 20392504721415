import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import TabGrid from "components/cards/TabCardGrid.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import DownloadApp from "components/cta/DownloadApp.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
// import Footer from "components/footers/MiniCenteredFooter"
// import Footer from "components/footers/FiveColumnDark"
// import Footer from "components/footers/SimpleFiveColumn"

// import chefIconImageSrc from "images/chef-icon.svg";
// import celebrationIconImageSrc from "images/celebration-icon.svg";
import serviceIcon from "images/service-icon.svg"
import buildingIcon from "images/building-icon.svg"
import shopIconImageSrc from "images/shop-icon.svg";
import products from "images/laptops.avif";
// import products from "images/main_page_products.jpg"

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  return (
    <AnimationRevealPage>
      <Hero
        heading={<>A trusted partner for  <HighlightedText>Dell & Lenovo</HighlightedText></>}
        description="We deal in Laptops, Desktops, Accessories, Workstations, Servers, and all other products for businesses and end user."
        imageSrc={products}
        imageCss={imageCss}
        primaryButtonEnable={false}
        addHeader={true}
        // imageDecoratorBlob={true}
        // primaryButtonText="Order Now"
        // watchVideoButtonText="Meet The Chefs"
      />
      <MainFeature
        subheading={<Subheading>Established Since 2014</Subheading>}
        heading={
          <>
            We've been in business for
            <wbr /> <HighlightedText>over 7 years.</HighlightedText>
          </>
        }
        description={
          <Description>
            We are Regional Distributor (Gold Consumer Partner) for Dell India and Consumer Partner for Lenovo
            <br />
            <br />
            With 3 exclusive Dell stores and B2B business for Dell & Lenovo you can trust us with all your computing requirements.
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        // primaryButtonText="Latest Offers"
        imageSrc={
          "https://i.dell.com/is/image/DellContent/content/dam/ss2/page-specific/dell-homepage/apj/modules/show-workstations-sb-dell-precision-7865-uhp-2401-01-in-homepage-module-lf-1023x842-lr.png?fmt=png-alpha&wid=1023&hei=842"
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      <Hero
        heading={<>Laptop is slow? <HighlightedText>We got you!</HighlightedText></>}
        description="Fixing your PC is not difficult anymore with our experienced technicians who already served 500+ customers."
        imageSrc={products}
        imageCss={imageCss}
        primaryButtonEnable={true}
        // imageDecoratorBlob={true}
        primaryButtonText="Contact Us"
        // watchVideoButtonText="Meet The Chefs"
      />
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
      <TabGrid
        heading={
          <>
            Checkout our <HighlightedText>Products.</HighlightedText>
          </>
        }
      />
      <Features
        heading={
          <>
            Amazing <HighlightedText>Services.</HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: shopIconImageSrc,
            title: "3 locations",
            description: "Dell Exclusives Stores-Tonk Road, Pratap Nagar, Model Town ",
            url: "https://google.com"
          },
          {
            imageSrc: buildingIcon,
            title: "Corporate office for B2B",
            description: "For bulk orders or complete solutions visit our coporate office",
            url: "https://timerse.com"
          },
          {
            imageSrc: serviceIcon,
            title: "Service Center",
            description: "Visit any of our stores for PC service",
            url: "https://reddit.com"
          }
        ]}

        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      />
      {/* <Testimonial
        subheading=""
        heading={<>Customers <HighlightedText>Love Us.</HighlightedText></>}
      /> */}
      {/* <DownloadApp
        text={<>People around you are ordering delicious meals using the <HighlightedTextInverse>Treact App.</HighlightedTextInverse></>}
      /> */}
      <Footer />
    </AnimationRevealPage>
  );
}
